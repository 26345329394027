import dynamic from 'next/dynamic'
import { useState } from 'react';
import { DynamicDefault, DynamicSynthwave, DynamicSerenity, DynamicDreamscape } from '@/components/DynamicComponents';

import StationSelector from '@/components/StationSelector';

export default function Home() {
  const [currentStation, setCurrentStation] = useState('Default');

  const renderComponent = (stationName) => {
    switch (stationName) {
      case 'Default':
        return <DynamicDefault />;
      case 'Synthwave':
        return <DynamicSynthwave />;
      case 'Serenity':
        return <DynamicSerenity />;
      case 'Dreamscape':
        return <DynamicDreamscape />;
      default:
        return <DynamicDefault />;
    }
  };

  return (
    <main className='h-full w-full flex justify-center items-center'>
      <StationSelector onStationChange={setCurrentStation} />
      {currentStation && renderComponent(currentStation)}
    </main>
  );
}
