// components/DynamicComponents.js
import dynamic from 'next/dynamic';

export const DynamicDefault = dynamic(() => import('./Stations/Default'), {
  ssr: false,
});
export const DynamicSynthwave = dynamic(() => import('./Stations/Synthwave'), {
  ssr: false,
});
export const DynamicSerenity = dynamic(() => import('./Stations/Serenity'), {
  ssr: false,
});
export const DynamicDreamscape = dynamic(() => import('./Stations/Dreamscape'), {
  ssr: false,
});